import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';

import { SimpleRule } from '../../types/domain';
import { createRuleDescription } from '../create-rule-description';

export const toSimpleRules = (
  rules: LoyaltyEarningRule[],
  flowAPI: ControllerFlowAPI,
  currency: string,
  locale: string,
  loyaltyProgram: LoyaltyProgram,
): SimpleRule[] => {
  const rulesArray = rules.reduce((prev, rule) => {
    const results: SimpleRule[] = [];

    if (rule.status !== Status.ACTIVE) {
      return prev;
    }
    if ('conversionRate' in rule) {
      rule.conversionRate!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription(rule, flowAPI, currency, locale, loyaltyProgram, index),
          tierId: config.tierId,
        };
        results.push(result);
      });
    } else if ('fixedAmount' in rule) {
      rule.fixedAmount!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription(rule, flowAPI, currency, locale, loyaltyProgram, index),
          tierId: config.tierId,
        };
        results.push(result);
      });
    }

    return [...prev, ...results];
  }, [] as SimpleRule[]);
  return rulesArray;
};
