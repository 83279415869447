import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getCouponSubtitle } from '@wix/loyalty-coupon-names';

interface CreateRewardDescriptionParams {
  reward: Reward;
  loyaltyProgram: LoyaltyProgram;
  flowAPI: ControllerFlowAPI;
  locale: string;
  currency: string;
  configIndex: number;
  coupon?: CouponReference;
}

const formatMoneyAmount = (amount: number, locale: string, currency: string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount);

export const createRewardDescription = ({
  reward,
  loyaltyProgram,
  flowAPI,
  locale,
  currency,
  configIndex,
  coupon,
}: CreateRewardDescriptionParams) => {
  let discountLabel: string | undefined;
  let costInPoints: number | undefined;

  const { translations } = flowAPI;
  const { t } = translations;

  if (reward.type === RewardType.DISCOUNT_AMOUNT) {
    const config = reward.discountAmount?.configsByTier?.[configIndex]!;
    const amount = parseFloat(config.amount ?? '0');

    costInPoints = config.costInPoints;
    discountLabel = t('app.reward-description.discount-amount', {
      discountAmount: formatMoneyAmount(amount, locale, currency),
    });
  } else if (reward.type === RewardType.COUPON) {
    const config = reward.coupon?.configs?.[configIndex]!;
    costInPoints = config.requiredPoints;
    discountLabel = getCouponSubtitle({
      coupon: coupon!,
      locale,
      currency,
      t,
      referenceCoupon: true,
    });
  } else if (reward.type === RewardType.COUPON_REWARD) {
    const couponReward = reward.couponReward!;
    const configsByTier =
      couponReward.percentage?.configsByTier ??
      couponReward.fixedAmount?.configsByTier ??
      couponReward.freeShipping?.configsByTier;

    const config = configsByTier?.[configIndex]!;

    costInPoints = config.costInPoints;
    discountLabel = getCouponSubtitle({ locale, currency, t, couponReward, config });
  } else {
    discountLabel = t('app.reward-description.empty');
  }

  if (costInPoints === undefined || discountLabel === undefined) {
    return '';
  }

  const pointsKey = loyaltyProgram.pointDefinition?.customName
    ? 'app.reward-description.points-custom'
    : 'app.reward-description.points';

  const pointsLabel = t(pointsKey, {
    count: costInPoints,
    pointsName: loyaltyProgram.pointDefinition?.customName,
  });

  return `${pointsLabel} = ${discountLabel}`;
};
