import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';

import { SimpleReward } from '../../types/domain';
import { createRewardDescription } from '../create-reward-description';

export const toSimpleRewards = (
  rewards: Reward[],
  coupons: CouponReference[],
  flowAPI: ControllerFlowAPI,
  currency: string,
  locale: string,
  loyaltyProgram: LoyaltyProgram,
): SimpleReward[] => {
  const commonDescParams = {
    loyaltyProgram,
    flowAPI,
    locale,
    currency,
  };

  return rewards.reduce<SimpleReward[]>((result, reward) => {
    if (!reward.active) {
      return result;
    }

    const simpleRewards: SimpleReward[] = [];
    const title = reward.name ?? '';

    if (reward.type === RewardType.COUPON) {
      const coupon = coupons.find(({ couponId }) => couponId === reward.coupon?.templateId);
      if (!coupon) {
        return result;
      }
      reward.coupon?.configs?.forEach(({ tierId }, configIndex) => {
        simpleRewards.push({
          title,
          description: createRewardDescription({ ...commonDescParams, reward, configIndex, coupon }),
          tierId,
        });
      });
    } else if (reward.type === RewardType.DISCOUNT_AMOUNT) {
      reward.discountAmount?.configsByTier?.forEach(({ tierId }, configIndex) => {
        simpleRewards.push({
          title,
          description: createRewardDescription({ ...commonDescParams, reward, configIndex }),
          tierId,
        });
      });
    } else if (reward.type === RewardType.COUPON_REWARD) {
      const { couponReward } = reward;
      const configsByTier =
        couponReward?.percentage?.configsByTier ??
        couponReward?.fixedAmount?.configsByTier ??
        couponReward?.freeShipping?.configsByTier;

      configsByTier?.forEach(({ tierId }, configIndex) => {
        simpleRewards.push({
          title,
          description: createRewardDescription({ ...commonDescParams, reward, configIndex }),
          tierId,
        });
      });
    }

    return [...result, ...simpleRewards];
  }, []);
};
